.content-section{
    margin: 10px 10px 10px 10px;
}
.content-section .action-btn-sec{
    margin-right: 15px;
}
.p-accordion .p-accordion-header .p-accordion-header-link{
    padding: 10px !important;
    border: none !important;
    background: none !important;
}
.p-accordion-header-link{
    text-decoration: none !important;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border: 1px solid #f9f9f9;
    border-bottom: none;
}
.p-accordion p-accordiontab .p-accordion-content {
    background: #f9f9f9;
    border: 1px solid #f9f9f9
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    font-size: 10px
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: var(--theme-bg-color1);
    border-color: var(--theme-bg-color1);
    color: var(--theme-primary-color);
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #f9f9f9;
    border-color: #f9f9f9;
    color: var(--theme-primary-color);
}    
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.filter-sec .ng-select .ng-select-container{
    background-color: transparent
}
.client-dashboard #my-tab .tabs__panel{
    padding: 0px !important
}
.bold-text{
    font-weight: bold;
}
.semi-bold-text{
    font-weight: 600;
}
#matrix-tab .tabs__panel{
    padding-bottom: 5px !important;
} 
.client-app #layoutSidenav #layoutSidenav_nav .sb-sidenav{
    padding-top: 56px !important;
}
.client-app .sb-topnav{
    margin-left: 5px !important;
}
.client-app .sb-topnav{
    z-index: 1090 !important;
}
.client-app .sb-topnav .navbar-brand{
    display: block
}
.client-app .sb-topnav .p-breadcrumb{
    background: transparent !important;
    border: none !important;
}
.has-collapsed-menu .client-app .top-head-sec,  .has-collapsed-menu .client-app .sb-sidenav-footer{
    display: none
}
.has-collapsed-menu  .client-app #layoutSidenav #layoutSidenav_nav{
    width: 0px
}
.has-collapsed-menu .client-app #layoutSidenav #layoutSidenav_content{
    margin-left: 0px !important;
}
.client-app #layoutSidenav #layoutSidenav_content{
    margin-left: 0px !important;
}
