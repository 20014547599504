.sb-sidenav-menu .navbar-brand {
    // margin-bottom: 10px;
    padding-top: 0.7rem;
    margin-right: 7px;
    margin-left: 8px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    padding-top: 0px
}

#layoutSidenav #layoutSidenav_nav {
    border-right: 1px solid #dadeea;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover,
.sb-sidenav-dark .sb-sidenav-menu a.active-link {
    background-color: var(--theme-bg-color);
    color: var(--theme-primary-color);
    border-right: 2px solid var(--theme-primary-color);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon,
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: #0f1930;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon,
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-sidenav-collapse-arrow,
.sb-sidenav-dark .sb-sidenav-menu .active-link .sb-nav-link-icon {
    color: var(--theme-primary-color);
}

.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading,
.sb-sidenav-dark .sb-sidenav-menu .nav-link {
    color: #0f1930;
}

.sb-sidenav-dark .sb-sidenav-footer {
    background-color: transparent;
}

    .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
        width: 225px;
        transform: translateX(0);
    }
    .has-collapsed-menu .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
        width: 50px;
        transition: width .3s ease-in-out;
        -webkit-transition: width 0.3s ease-in-out;
        transform: translateX(0);
    }

    .sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
        font-size: 1.1rem;
        width: 26px;
        margin-right: 22px;
    }
    .sb-nav-fixed #layoutSidenav #layoutSidenav_content {
        margin-left: 225px;
        padding-left: 0px;
    }
    .sb-sidenav-footer fa-icon {
        margin-right: 17px;
    }
    .sb-sidenav-footer .menu-toggle-btn fa-icon{
        margin-right: 23px;
    }
    .has-collapsed-menu .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
        transition: width .3s ease-in-out;
        -webkit-transition: width 0.3s ease-in-out;
    }
    #layoutSidenav #layoutSidenav_nav {
        transition: width .3s ease-in-out;
        -webkit-transition: width 0.3s ease-in-out;
    }
    .has-collapsed-menu .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
        margin-left: 55px;
    }
    .sb-sidenav {
        transition: width .3s ease-in-out;
        -webkit-transition: width 0.3s ease-in-out;
    }
    .sb-sidenav-footer a {
        display: flex;
        width: 225px;
        padding-left: 0.2rem;
    }
    .sb-sidenav-footer div, .sb-sidenav-footer .collapse-text, .menu-normal-view-icon, .desk-view {
        display: block;
    }
    .menu-mobile-view-icon, .mb-view {
        display: none;
    }
    .has-collapsed-menu .sb-sidenav-toggled .sb-sidenav-footer div, .has-collapsed-menu .sb-sidenav-toggled .sb-sidenav-footer .collapse-text{
        display: none;
    }
    .modal-open .sb-nav-fixed #layoutSidenav #layoutSidenav_nav{
        z-index: 0;
    }
    @media screen and (max-width: 768px) {
        .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
            width: 50px;
            transform: translateX(0);
        }
        .sb-sidenav-footer div, #sidebarToggle {
            display: none;
        }
    }
    // @media screen and (min-width: 768px){
    //     .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    //         z-index: 0;
    //     }
    // }
    // @media screen and (width: 768px) {
    //     .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    //         z-index: 1080;
    //     }
    // }

.sb-sidenav-footer .menu-btn fa-icon{
    width: 25px;
    text-align: left
}
.sb-sidenav-footer .menu-btn:not(:first-child){
   margin-top: 15px;
}
