// Global styling for this template

html,
body {
  height: 100%;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.custom-selects option {
  text-align: left;
  background-color: #fff;
  color: #000;
}
.custom-selects , .custom-selects:focus, .custom-selects:hover{
  background-color: #ccc !important;
    color: #000 !important;
    border: 1px solid #ccc !important;

}

.custom-selects::after {
  content: '\25BC'; /* Unicode character for the down arrow */
  margin-left: 0.5rem; /* Adjust the margin as needed */
}
