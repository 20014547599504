.p-menu {
  ul {
      margin-bottom: 0;

      .p-menuitem-link {
        &:hover {
          text-decoration: none;
        }
      }
  }
}