.p-overlaypanel {  
  box-shadow: rgba(0, 0, 0, 0.2) 2px 11px 15px -7px, rgba(0, 0, 0, 0.14) 2px 24px 38px 3px, rgba(0, 0, 0, 0.12) 2px 9px 46px 8px;
  border-radius: .5rem;
    .p-overlaypanel-content {
        padding: 1rem;
    }
}

.p-overlaypanel-close {
    border-radius: 50%;
    background-color: rgb(0, 153, 204);

    height: 1.5rem;
    width: 1.5rem;
}

.p-overlaypanel-close-icon {
    font-size: 0.75rem
}