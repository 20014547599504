:root {
    --theme-primary-color: #0099cc;
    --theme-primary-color1: #0084b0;
    --theme-bg-color: #c1e6f3;
    --theme-bg-color1: #eefaff;
    --theme-border-color: #d7eff8;
    --theme-text-color: #0f1930;
}
.mb-10{
    margin-bottom: 10px
}
.bg-dark {
    background-color: #fff !important;
}
h1{
    padding: 0px 10px;
    font-size: 2rem;
}
.sb-topnav.navbar-dark #sidebarToggle {
    color: #0f1930;
}

.sb-sidenav-dark {
    background-color: #fff;
    color: #0f1930;
}

// .sb-topnav .navbar-brand img{
//     width: 100%
// }
// .sb-sidenav-menu .navbar-brand img{
//     width: 100%;
// }


// .sb-nav-fixed .sb-topnav{
//     z-index: 0
// }
.sb-topnav .navbar-brand {
    display: none
}


.breadcrumb {
    background-color: var(--theme-bg-color);
}

.breadcrumb-item.active {
    color: #0f1930;
}

.table-common tr td:nth-child(2) {
    text-align: right
}

.table-common {
    width: 100%
}

.table-common tr td {
    padding: 5px
}

.theme-bg-color {
    background-color: var(--theme-bg-color)
}

.card-header {
    background-color: var(--theme-bg-color);
    border-bottom: 1px solid var(--theme-border-color);
    color: var(--theme-primary-color);
    font-size: 17px;
}

.breadcrumb-item.active {
    color: var(--theme-primary-color);
}

.card {
    border: 1px solid var(--theme-border-color);
}

.card-footer {
    background-color: var(--theme-bg-color);
    border-top: 1px solid var(--theme-border-color);
}

.footer-bottom {
    padding: 14px 0;
    background-color: var(--theme-bg-color) !important;
    border-top: 1px solid var(--theme-border-color);
}

a:hover {
    color: var(--theme-primary-color);
}

.right-sec .card {
    height: calc(100% - 1.5rem)
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 0rem !important; // fix tab bottom over spacing issue
}

.tabs__tab-bar {
    align-self: auto !important;
    border-radius: 1px !important;
    margin-bottom: 0px !important;
    border: none !important;
    border-bottom: 2px solid var(--theme-primary-color) !important;
}

.tabs__tab-bar .tabs__tab {
    padding: 10px 20px !important;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    margin-top: 0px;
    // padding-bottom: 10px;
    padding-top: 10px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: 55px;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
}

.btn:focus,
.sb-top-nav .btn.focus {
    outline: 0;
    box-shadow: none;
}


.btn-success {
    color: #fff;
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.form-control {
    border-radius: 2px;
}

.form-control:focus,
.ng-autocomplete .autocomplete-container .input-container input:focus {
    box-shadow: none;
    border: 1px solid #535966;
}

.ng-autocomplete .autocomplete-container .input-container input {
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
}

.modal-backdrop {
    background-color: rgba(96, 96, 96, .5);
}

.modal-header {
    background-color: var(--theme-bg-color1);
    border-bottom: none
}

.modal-header .modal-title {
    color: var(--theme-primary-color);
    text-align: center;
    width: 100%;
    font-weight: 600;
}

.modal-footer {
    background-color: var(--theme-bg-color1);
    border-top: none
}

.modal-content {
    border: 1px solid var(--theme-border-color);
    border-radius: 10px
}

.sb-sidenav-menu .ng-scroll-content {
    width: 100%;
}


.cdk-overlay-pane {
    height: auto !important;
}

.full-width {
    width: 100%
}

.autocomplete-container .suggestions-container ul {
    max-height: 180px !important;
}

.lable-title {
    font-weight: bold
}

.ng-select .ng-select-container {
    border-radius: 2px;
    border: 1px solid #ced4da;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: var(--theme-text-color);
    background-color: #e8e8e8;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: rgba(0, 0, 0, .87);
    font-size: 15px;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none;
    border: 1px solid #ced4da
}

.ng-select .ng-select-container .ng-value-container .ng-input>input:focus .ng-select .ng-select-container {
    border: 1px solid #ced4da;
}

.autocomplete-container .suggestions-container ul li a {
    padding: 9px 15px !important;
}

.modal-backdrop.show {
    opacity: 0.9;
}
.modal-header {
    padding: 0.7rem 1rem;
}
.modal-body{
    min-height: 140px;
}
.modal-body-div-center{
    height: calc(100% - 2rem);
    position: absolute;
    width: 100%;
}

.sb-nav-fixed .sb-topnav {
    z-index: 0;
}
.modal-content {
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.10), 0px 24px 38px 3px rgba(0, 0, 0, 0.18), 0px 9px 46px 8px rgba(0, 0, 0, 0.10);
    border: 0 none;
}

.template-sec .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 150px;
}
// .modal-body .ng-dropdown-panel .ng-dropdown-panel-items {
//     max-height: 150px;
// }

.modal-content .modal-footer fa-icon{
    padding-left: 5px
}
// .modal-full-width .modal-dialog {
//     width: calc(100% - 225px);
//     height: 100%;
//     margin: 0;
//     padding: 0;
//     max-width: calc(100% - 225px);
//     right: 0;
//     position: fixed;
//     transform: translateX(0);
//     -webkit-transition: width 0.3s ease-in-out;
// }
.modal-full-width .modal-dialog .modal-content{
    height: 100%;
}
.mt-10{
    margin-top: 10px
}
.mt-20{
    margin-top: 20px
}
.ml-10{
    margin-left: 10px
}
.ml-15{
    margin-left: 15px
}
.mr-15{
    margin-right: 15px
}
.mT5{
    margin-top: 5px;
}
// .modal{
//     width: calc(100% - 55px);
//     left: auto;
//     right: 0;
//     transform: translateX(0);
//     -webkit-transition: width 0.3s ease-in-out;
// }
.d-block{
    z-index: 2000;
}
.modal-backdrop.show{    
    z-index: 2000 !important;
}
// .has-collapsed-menu .modal{
//     width: calc(100% - 225px);
//     transform: translateX(0);
//     -webkit-transition: width 0.3s ease-in-out;
// }

// .has-collapsed-menu .modal-full-width .modal-dialog {
//     width: calc(100% - 225px);
//     height: 100%;
//     margin: 0;
//     padding: 0;
//     max-width: calc(100% - 225px);
//     right: 0;
//     position: fixed;
//     transform: translateX(0);
//     -webkit-transition: width 0.3s ease-in-out;
// }
@media screen and (min-width: 835px) {
    // .sb-sidenav-toggled .modal{
    //     width: calc(100% - 55px);
    // }
}
.full-screen-popup {
    z-index: 2000;
}
.full-screen-popup .modal-dialog{
    max-width:100%;
    height: 100%;
    margin:0px;
    padding: 25px 50px;
}
.full-screen-popup .modal-content{
    width: 100%;
    height: 100%;
}

.full-screen-popup1 .modal-content{
    width: 80%;
    height: 100%;
    margin: 0 auto;
}
.full-screen-popup .modal-dialog-centered{
    min-height: 100%;
}
.marT20{
    margin-top:20px;
}
.sb-sidenav-toggled .modal {
    background: chocolate
}

.p-radiobutton-label {
    line-height: 1;
    padding-right: 10px;
    margin-bottom: 2px;
    font-size: 15px;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: var(--theme-primary-color) !important;
  }  
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color:var(--theme-primary-color) !important;
    background-color:  #FFFFFF !important;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus{
    box-shadow: 0 0 0 0 !important;
  }
.v-middle{
    display: flex;
    align-items: center;
}
.tree-element{
    margin-left:30px;
}
.radio-section{
    display: flex
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: var(--theme-border-color);
    color: #495057;
    border-radius: 3px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
    border-right: none;
    float: right;
    /* background: #6c757d; */
    border-radius: 10px;
    color: #6c757d;
    font-weight: bold;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{
     background: #6c757d;
    color: white;
    font-weight: 500;
}
.flex-sec{
    display: flex
}
.action-btn-sec{
    position: absolute;
    right: 0;
    text-align: right;
    z-index: 999;
}
.btn-theme:hover {
    color: #fff;
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}
.btn-theme {
    color: var(--theme-primary-color);
    background-color: var(--theme-bg-color);
    border-color: var(--theme-border-color);
}
.btn-theme-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f8d7da;
}
.btn-theme-danger:hover {
    color: #fff;
    background-color: #bd2130;
    border-color: #bd2130;
}
/* Works on Firefox */
.multiple-select-section  .ng-select-container{
    max-height: 150px;
    overflow-y: auto !important;
    scrollbar-width: thin;
    scrollbar-color: #ccc #fafafa;
    overflow-x: hidden
}
/* Works on Chrome, Edge, and Safari */
.multiple-select-section .ng-select-container::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}  

.multiple-select-section .ng-select-container::-webkit-scrollbar-track {
    background: #fafafa;        /* color of the tracking area */
}
  
.multiple-select-section .ng-select-container::-webkit-scrollbar-thumb {
    background-color: #ccc;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #fafafa;  /* creates padding around scroll thumb */
}
.multiple-select-section ng-select{
    display: flex
}
.multiple-select-section  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    height: 100%;
    width: auto;
    max-width: 90%;
}
.multiple-select-section .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 95%;
    overflow: hidden;
}
.multiple-select-section .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    padding: 0.05rem 0.3rem 0.05rem 0.1rem;
    margin-right: 0.5rem;
    background: var(--theme-border-color);
    color: #495057;
    border-radius: 3px;
    font-size: .8em;
    max-width: 100%;
}
.multiple-select-section .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    margin: 2px 0px 0px 0px;
    padding: 0px 5px;
    border-radius: 50px;
}
.required:after {
    content:" *";
    color: #f7660f;
}
.mtb-5{
    margin-top: 5px;
    margin-bottom: 5px;
}
.btn-right-align{
    text-align: right
}
.btn-delete-icon{
    padding: 0.3rem 0.3rem;
    font-size: 14px;
}
.btn-delete-icon:hover{
    color: #f3733b;
}
/* Works on Firefox */
.css-scrollbar{ 
    overflow-y: auto !important;
    scrollbar-width: thin;
    scrollbar-color: #ccc #fafafa;
    overflow-x: hidden
}
/* Works on Chrome, Edge, and Safari */
.css-scrollbar::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}  

.css-scrollbar::-webkit-scrollbar-track {
    background: #fafafa;        /* color of the tracking area */
}
  
.css-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #fafafa;  /* creates padding around scroll thumb */
}
.sm-btn-theme{
    font-size: 13px;
    padding: 3px 8px;
}
.checkbox-section{
    display: flex
}
h6{
    font-weight: 600;
}
.pi-chevron-right:before{
    font-size: 11px !important;
}
.p-breadcrumb {
    background: var(--theme-bg-color) !important;
    border: 1px solid var(--theme-border-color)!important;
}
.p-breadcrumb{
    padding: .5rem!important;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
    box-shadow: none !important;
}
.p-breadcrumb ul li .p-menuitem-link:hover {
    cursor: pointer;
}
.p-datatable .p-sortable-column:focus {
    box-shadow: none  !important;
}
.tr-div{
    display: flex
}
.error-msg {
    color: red;
}
.ql-toolbar.ql-snow{
    padding: 5px;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px;
}
.ql-snow .ql-picker.ql-font{
    width: 100px;
}
.ql-snow .ql-picker.ql-header {
    width: 80px;
}
.ql-snow .ql-picker-label{
    padding-left: 5px;
}
.ql-snow .ql-picker{
    font-size: 12px;
}

#layoutSidenav #layoutSidenav_content ng-scrollbar .ng-scroll-viewport{
    contain: initial !important
}

.footer-action-btn-sec {
    padding: 1em;
    position: fixed;
    bottom: 0;
    border-top: 1px solid var(--theme-border-color);
    background-color: var(--theme-bg-color1);
    width: 100%;
    z-index: 300;
}

.p-sortable-column-icon{
    font-weight: 50 !important;
    font-size: 13px !important;
}
.p-datatable{
    font-size: 14.5px;
}
.p-sortable-column-icon {
    font-size: 10px !important;
}
.pr-5{
    padding-right: 5px !important;
}
.plr-5{
    padding-left: 5px;
    padding-right: 5px;
}
.p-datatable .p-datatable-thead > tr > th input{
    border: 1px solid #ced4da;
    padding: 2px 3px;
 }
 .p-datatable .p-datatable-thead > tr > .search-th{
    border-top: 1px solid #e9ecef;
    padding-top: 10px;
    padding-bottom: 10px;
 }
 .p-datatable .p-datatable-thead > tr > th .btn{
    padding: 0px 5px !important;
    color:  #6a7075;
    font-size: 0.8rem;

 }
 .paccordion-head-bg{
     padding-right: 15px
 }
 .paccordion-head-bg .p-accordion .p-accordion-header .p-accordion-header-link, .paccordion-head-bg .status-section {
    border: 1px solid #f6f1f1 !important;
    background: #f9f9f9 !important;
}
.paccordion-head-bg p-accordiontab .p-accordion-tab, .paccordion-head-bg .status-section{
    margin-bottom: 10px !important
}
.paccordion-head-bg .status-section{
    color: #495057;
    font-weight: 600;
    padding: 10px 0px !important;
} 
.paccordion-head-bg .status-section div:nth-child(1){
    padding-left: 30px
} 
.paccordion-head-bg .accordion-header{
    width: 100%;
    display: flex;
    padding: 0px
}
.paccordion-head-bg  .accord-header{    
    width: 100%;
    display: flex;
    padding: .5rem 1rem;
    background: var(--theme-bg-color);
    border-color: var(--theme-border-color);
    margin-bottom: 10px;
    border-radius: 2px;
}
.paccordion-head-bg  .accordion-header-left{
    width: 70%;
    text-align: left
}
.paccordion-head-bg  .accordion-header-right{
    text-align: right
}

.paccordion-head-bg  .box-container{
    background: #f9f9f9;
}
.paccordion-head-bg  .box-container-inner-section{
    background: white;
    padding: 10px;
    height: 100%;
}
.paccordion-head-bg .box-section{
    max-height: 200px
}
.paccordion-head-bg .box-header-sec{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px
}
.paccordion-head-bg .box-list{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}
.paccordion-head-bg  .box-list .btn{
    width: max-content;
}
.paccordion-head-bg  .checkbox-section label{
    padding-left: 5px
}  
.filter-table .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    max-width: 100% !important;
}
.primary-color{
    color: var(--theme-primary-color)
}
.example-list {
    width: 100%;
    // min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
  }
  
  .example-box {
    padding: 5px 0px;
    color: rgba(0, 0, 0, 0.87);
    background: white;
    //font-size: 14px;
    align-items: center;   
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
  }
  
  .cdk-drag-preview {
    // box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(113, 181, 78, 0.2),
                0 8px 10px 1px rgba(113, 181, 78, 0.14),
                0 3px 14px 2px rgba(113, 181, 78, 0.12);
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .example-box:last-child {
    border: none;
  }
  
  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .example-custom-placeholder {
    background: #ccc;
    min-height: 60px;
    height: auto;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .example-list .multiple-select-section, .example-list .multiple-select-section ng-select{
      width: 100%
  }
  .dragButton{      
    cursor: move !important;
  }


  .cdkDrag-list {
    width: 100%;
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
  }
  
  .cdkDrag-box {
    padding: 5px 0px;
    color: rgba(0, 0, 0, 0.87);
    background: white;
    font-size: 14px;
    align-items: center;   
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
  }
  
  .cdk-drag-preview {
    // box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .cdkDrag-box:last-child {
    border: none;
  }
  
  .cdkDrag-list.cdk-drop-list-dragging .cdkDrag-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .cdkDrag-custom-placeholder {
    background: #ccc;
    min-height: 60px;
    height: auto;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  iframe{
    border: 1px solid #e3eae0;
  }
  .container-fluid h1{
    word-break: break-word;
    margin: 0px 0px 0px;
    font-size: 1.75rem
  }
 .modal-dialog-scrollable{
    max-height: 100% !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}
.modal-dialog-scrollable .modal-content{
    height: 100% !important;
}
.p-inputtext{
    padding: 0.375rem 0.75rem !important;
    border-radius: 2px !important;
}
.pT10{
    padding-top:10px
}
.p-inputtext:enabled:hover {
    border-color: #ced4da !important;
  }
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border: 1px solid #535966 !important;
}
.text-right{
    text-align: right;
}
.table-scrollxy-div .p-datatable table{
    table-layout: auto !important
}
.table-scrollxy-div .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td, .table-scrollxy-div .p-datatable .p-datatable-thead > tr > th{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    border: none;
    font-size: 14px !important;
    padding: 7px 10px !important;   
}
// .table-scrollxy-div .p-datatable-gridlines{
//     padding-right: 10px !important;   
// }
.table-scrollxy .ng-scroll-content{
    padding-right: 10px !important;   
    padding-bottom: 10px !important;   
}
.tooltip-show{
   display: block
}
.tooltip-hide{
    display: none
}
.p-table-style .p-datatable-thead > tr > th, .p-table-style .p-datatable-tbody > tr > td{
    padding: 0.3rem 0.5rem !important;
    border: none !important
}
.p-datatable .p-datatable-thead > tr > th .p-input-icon-left input {
    padding-left: 2rem !important;
    border-radius: 2px
}
   
.modal-body .p-datatable .p-paginator{
    padding: 0.3px 0.5px !important;
    justify-content: flex-end;
    border: none
}
.modal-body .p-datatable .p-paginator .p-paginator-first, .modal-body .p-datatable .p-paginator .p-paginator-prev, .modal-body .p-datatable .p-paginator .p-paginator-next, .modal-body .p-datatable .p-paginator .p-paginator-last {
    min-width: 1.5rem;
}
.modal-body .p-datatable .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem;
    height: 2rem;
}
.p-0{
    padding: 0px
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
    margin-left: 3rem;
}
.sb-sidenav-dark .sb-sidenav-menu a.active-link .sb-sidenav-collapse-arrow {
    color: var(--theme-primary-color);
}
// .sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-nested .nav-link{
//     padding-top: 7px;
//     padding-bottom: 7px;
// }
.label-light-b{
    font-weight: 600
}

.padR-20{
    padding-right:20px;
}
.checkbox-lable-parent label{
    padding: 0px;
    padding-left: 10px
}
.mr-10{
    margin-right: 10px
}
.prl-0{
    padding-right: 0px;
    padding-left: 0px
}
/*loader css starts*/
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1px;
    transform: translate(-50%, -50%);
    width: 220px;
    z-index: 9999999;
}
.loader-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000026;
    z-index: 999999;
}
/*loader css end*/

.change-layoutSidenavContent-height #layoutSidenav_content{
    min-height: calc(100vh - 75px) !important;
}
.list-details .p-breadcrumb{
    background-color: transparent !important
}
.p-menuitem-link{
    font-weight: 600
}
.table-scrollxy-div .p-datatable .p-datatable-thead > tr > th{ 
    position: sticky;
    top: 0;
}

.custom-tooltip{
    display: inline-block;    
    overflow-wrap: break-word;
}
.p-tooltip-text{
    font-size: 13px
}
#layoutSidenav #layoutSidenav_content{
    min-height: 100vh;
}
tabs{
    min-height: auto !important;
}
.sb-sidenav-menu .navbar-brand{
    white-space: initial;
    font-size: 15px !important;;
    font-weight: 600!important;;
}
.gptable .p-datatable-tbody > .gpRow{
    background: white !important
}
.gptable .p-datatable-tbody > tr{
    background: #fcfcfc !important
}
.mB5{
    margin-bottom: 5px
}
.mb-scroll-show canvas{
    width: 100% !important;
}
a{
    cursor: pointer;
}
.ml-25{
    margin-left: 25px
}
#matrix-tab .p-datatable-loading-icon {
    margin-top: 20px !important;
}
.pl-30{
    padding-left: 30px
}
.font-bold{
    font-weight: bold
}
.pad-0{
    padding: 0px
}
/*
.btn-success:hover, .btn-success:focus, .p-button:enabled:hover{
    background-color: var(--theme-primary-color1);
    border-color: var(--theme-primary-color1);
}
.btn-success.disabled, .btn-success:disabled, .p-checkbox .p-checkbox-box.p-highlight, .p-button{
    background-color: var(--theme-primary-color) !important;
    border-color: var(--theme-primary-color) !important;
}
*/
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{    
    border-color: var(--theme-primary-color) !important;
}
.p-dropdown:not(.p-disabled):hover, .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color:  var(--theme-primary-color)!important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-datepicker table td > span.p-highlight{
    background: var(--theme-bg-color)!important;
}
.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
    color: var(--theme-primary-color)!important;
}
//.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus, .p-link:focus, .p-dropdown:not(.p-disabled).p-focus {
//    box-shadow: 0 0 0 0.2rem var(--theme-bg-color)!important;
//}
//.btn-success:focus, .btn-success.focus {
//    color: #fff;
//    background-color: var(--theme-primary-color)!important;
//    border-color: var(--theme-primary-color1)!important;
//    box-shadow: 0 0 0 0.2rem var(--theme-bg-color)!important;
//}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--theme-bg-color1)!important;
    border-color: var(--theme-border-color)!important;
    color: #495057;
}
.p-checkbox .p-checkbox-box.p-highlight{
    background-color: var(--theme-primary-color)!important;
    background: var(--theme-primary-color)!important;

}
.p-datatable .p-component-overlay{
    background-color: rgba(238, 250, 255, 0.43)!important;
}
.p-toast .p-toast-message.p-toast-message-success{
    background: var(--theme-border-color)!important;
    border-color: var(--theme-primary-color)!important;
    color: var(--theme-primary-color1)!important;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close{
    color: var(--theme-primary-color1)!important;
}
.multiple-select-section .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label.count{
    max-width: 100% !important;
}
.label-bold{
    font-weight: 700
}
.label-link{
    cursor: pointer;
    color: var(--theme-primary-color);
}
.label-link:hover{
    color: var(--theme-primary-color1);
}
.p-tabview .p-tabview-nav, .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    border-bottom: 2px solid var(--theme-primary-color) !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    padding: 12px 20px !important;
    text-decoration: none;
    color: #212529 !important;
    font-weight: 400 !important;
    font-size: 1rem;
    border-radius: 0px !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-color: none !important;
    background-color: var(--theme-bg-color) !important;
    color: var(--theme-primary-color) !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.p-tabview .p-tabview-panels{
    color: var(--theme-text-color)!important;
    margin-bottom: 100px;
    padding: 0.5rem !important;
}
.semi-bold{
    font-weight: 500
}
.mlr-25{
    margin-left: 25px;
    margin-right: 25px;
}
:host ::ng-deep{
    .pDate .p-datepicker table td {
        padding: 0px !important;
    }    
}
::ng-deep .p-datepicker table{
    background: red !important;
}
.mb-20{
    margin-bottom: 20px;
}
.comment-icon-btn{
    min-width: 35px;
    height: 30px;
    border-radius: 50%; 
    background-color: var(--theme-bg-color); 
    position: relative;
    top: 6.5px;
    left: 8px;
    margin: 0px 25px 0px auto;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  .comment-icon-btn::before{
    position: absolute;
    content: "";
    border-radius: 50%; 
    border: 4px solid red;
    right: 0px;
    top: 2px;
   }
   .comment-icon-btn::after{
    position: absolute;
    content: "";
    border-top: 13px solid var(--theme-bg-color);
    border-right: 11px solid transparent;
    top: 26px;
    left: 9px;
   }  
   .comment-box{
    background: #eaedf1;
    padding: 10px;
    label{
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
  .discussion-panel{
    .p-dialog-mask{
      top: 55px;
      height: calc(100% - 55px);
      align-items: start;
    }
    .p-dialog{
      height: calc(100% - 66px) !important;
      max-height: calc(100% - 66px) !important;
      background-color: #fff;
    }
    .p-dialog .p-dialog-header{
      padding: .5rem 1rem !important;
    }
    .p-dialog .p-dialog-content{
      padding: 0 1rem 2rem 1rem;
    }
  }
  .dashboard-filter-container{
    background-color: #f5f5f5;
    margin-top: 10px;
    border-radius: 2px;
    padding: 10px;
    .filter-container-inner{
      display: flex;
      justify-content: space-between;
      gap: 15px;
      .textarea-container{
        display: flex;
        gap: 15px;
        align-items: center;
      }
      &__left{
        width: 95%;
        .date-filter{
          display: block;
          .p-calendar{
            height: 35px;
          }
          .p-calendar-w-btn .p-inputtext{
            font-size: 14px;
          }
        }
        .date-filter label{
          display: block;
          width: 100% !important;
          line-height: 0.5;
        }
        &__parent{
          display: grid;
          grid-template-columns: repeat(5, calc(100% / 5));
          grid-column-gap: 1rem;
          align-items: center;            
          .p-multiselect, .p-dropdown{
            width: 100%!important;
          }
          .p-multiselect .pi, .p-dropdown-trigger-icon{
            font-size: 10px;
          }
          .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover, .p-dropdown-panel .p-dropdown-item:hover{
            background-color: #f1f1f1 !important;
        }
          .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight, .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
              color: var(--theme-text-color) !important;
              background-color: #e8e8e8 !important;
              font-weight: 600;
          }
          .p-multiselect-item{
              color: rgba(0, 0, 0, 0.87) !important;
              font-size: 15px;
          }
          .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus, .p-link:focus, .p-dropdown:not(.p-disabled).p-focus {
              box-shadow: none !important;
          }   
          .p-multiselect:not(.p-disabled):hover, .p-dropdown:not(.p-disabled):hover, .p-focus {
              border-color: #ced4da !important;
          }
          .p-multiselect-panel .p-multiselect-header .p-multiselect-close{
              width: 1rem;
              height: 1rem;
              margin-left: 0.3rem;
              .pi{
                font-size: small;
              }
          }
          .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{
            font-size: inherit;
          }
        }
      }
      .p-multiselect .p-multiselect-label {
        padding: 0.375rem 0.75rem !important;
      }
      .p-dropdown-panel{        
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 rgba(0,0,0,0.06);
        margin: -1px;
      }
      .p-multiselect-panel{
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 rgba(0,0,0,0.06);
        margin: -1px;
        .p-checkbox{
          width: 13px;
          height: 13px;
        }
        .p-checkbox .p-checkbox-box{
          width: 13px;
          height: 13px;    
          border: 1px solid #ced4da;
        }
        .p-checkbox .p-checkbox-box.p-highlight{
          background: var(--primary) !important;
          border-color: var(--primary) !important;
        }
      }
    .pdropdown-inner-selection{
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
        font-weight: 600 !important;
      }
      .p-dropdown .p-dropdown-panel{
        height: auto;
      }
      .p-dropdown-items-wrapper, .p-dropdown-item{
        overflow: visible;
        height: auto !important;
        max-height: 100% !important;
      }
      .pdropdown-inner-selection__item{
        overflow: auto;
        .pi-angle-right{
          font-size: 10px;
        }
      }
      &__panel{
        display: none;
        position: absolute;
        z-index: 1;
        padding: 0.25rem 0;
        // background: #ffffff;
        border: 0 none;
        // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 17rem;
        left: 100%;
        top: 0;
        font-weight: normal;
      }
      .pdropdown-inner-selection__item.show-child .pdropdown-inner-selection__panel{
        display: block;       
      }
      .ng-dropdown-panel .ng-dropdown-header{
        border-bottom: none;
        padding: 5px 7px 0px;
        .active-link{
          font-size: 13px;
          margin-bottom: 0;
          color: var(--theme-primary-color);
          cursor: pointer;
        }
      }
    }
    }
    .custom-dropdown {
      position: relative;
      display: inline-block;   
      .form-control{
        border-right: none;
      } 
      .form-control:focus{
        border-color: #ced4da !important;
      }
      .p-button{
        font-size: 10px;
        background-color: #fff !important;
        color: #6c757d;
        border-color: #ced4da !important;
        padding: 0.5rem 1rem;
        box-shadow: none;
        .pi{
         font-size: 10px;
        }
      }
      .p-button:hover{
        color: #6c757d;
      }
      &__panel {
        display: grid;
        position: absolute;
        background-color: #fff;
        width: 100%;
        z-index: 1;
        padding: 5px;
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        border-top: 0px;
        gap: 5px;
      }
    }
    
    .p-dropdown .p-dropdown-trigger, .p-multiselect .p-multiselect-trigger{
      border-left: 1px solid #ced4da;
    }
    
  }

#header-row {
    margin-bottom: 0.5rem;

    #header-column {
        padding-left: 0;
        padding-right: 0;
    }
}