@media screen and (max-width: 834px),
(width: 834px) {
    
    .sb-sidenav-footer fa-icon {
        margin-right: 10px;
    }
    .sb-topnav .navbar-brand {
        display: block
    }
    .sb-topnav .top-head-sec {
        display: flex;
        width: 100%;
    }
    .sb-topnav .top-head-sec .right-sec {
        text-align: right;
        width: inherit;
        // display: flex;
        justify-content: end;
        padding-top: 0.3125rem;
    }
    .modal{
        width: 100% ;
        left: 0;
    }
    .client-app  .sb-topnav .top-head-sec {
        display: none;
    }
}
@media screen and  (max-width : 480px), (max-width : 540px)  {
    /* Styles */
    #my-tab .tabs__panel{
        padding: 15px 0 !important;
    }
    .right-sec .card {
        height: 250px;
    }
    .container-fluid h1{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .radio-section{
        margin-bottom: 1rem
    }
    .no-record-section{
        justify-content: center
    }
    .tabs__tab-bar .tabs__tab {
        padding: 8px 15px !important;
        font-size: 14px;
        font-weight: 600;
    }
    .card-header{
        font-size: 14px;
        padding: 0.5rem;
    }
    .card-body{        
        padding: 0.5rem;
        font-size: 13px;
    }    
    .btn-right-align{
        text-align: left;
    }
    .row.form-group{
        margin-bottom: 0px;
    }
    .ql-snow .ql-picker{
        height: 20px;
        font-size: 12px;
    }
    .ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
        width: 20px;
    }
    .ql-snow .ql-picker.ql-header {
        width: 71px;
    }
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        position: absolute;
        margin-top: -7px;
        right: 0;
        top: 50%;
        width: 13px;
    }
    .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
        height: 18px;
        padding: 3px 4px;
        width: 20px;
        font-size: 1;
    }
    .ql-snow .ql-picker.ql-font {
        width: 85px;
    }
    .mb-scroll-show{
        width: 500px;
        padding-bottom: 15px
    }
    .mb-mb-10{
        margin-bottom: 10px
    }
    .mb-mr-10{
        margin-right: 10px !important
    }
}

@media screen and  (max-width : 640px){
    /* Styles */
    .action-btn-sec{
        position: inherit !important;
    }
}

@media screen and  (min-width : 550px), (max-width : 600px){
    /* Styles */
    .action-btn-sec{
       margin-bottom: 10px
    } 
}
@media screen and  (max-width : 550px){
    .action-btn-sec{
        margin-bottom: 0px;        
        text-align: left
     }
    h5, h4{
        font-size: 1rem;
    }
    h6 {
        font-size: 14px !important;
    }
    .btn-delete-icon{
        font-size: 11px;
    }
    .action-btn-sec .btn, .btn-success, .btn-secondary, .btn-danger, .btn-dark, .btn-theme, .upload-btn {
        font-size: 12px !important;
        padding: 0.2rem 0.5rem !important;
    }
    label.col-form-label{
        font-size: 13px;
    }
    .ng-select.ng-select-single .ng-select-container {
        height: 30px;
        min-height: 32px;
    }
    .ng-select .ng-select-container .ng-value-container .ng-input > input {
        font-size: 13px;
    }
    .ng-select .ng-arrow-wrapper{
        width: 20px;
    }
    .ng-select .ng-arrow-wrapper .ng-arrow{
        border-width: 4px 4px 2px;
    }
    .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
        border-width: 0 4px 4px;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
        font-size: 13px;
        padding: 5px 10px;
    }
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value, .ng-select.ng-select-single .ng-select-container .ng-value-container {
        font-size: 13px;
    }
    .ng-select .ng-clear-wrapper .ng-clear {
        font-size: 15px !important;
    }
    .ng-select .ng-clear-wrapper{
        width: 15px !important;
    }
    input.form-control, .p-inputtext{
        font-size: 13px !important;
        height: calc(1.1rem + 0.75rem + 2px)!important;
    }
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        font-size: 13px;
    }
    .table-search-btn-section span{
        font-size: 13px
    }
    .table-search-btn-section .btn{
        font-size: 12px
    }    
    .container-fluid h1{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .col-auto{
        width: 100%
    }
    
    .mb-mt-10{
        margin-top: 10px
    }
    
    .mb-mb-10{
        margin-bottom: 10px
    }
    .p-breadcrumb ul li.p-breadcrumb-chevron {
        margin: 0 0.4rem 0 0.4rem;
    }
    .pi-chevron-right::before {
        font-size: 8px !important;
    }
    .p-breadcrumb {
        padding: 2px 6px !important;
    }
    .p-toast{
        width: 100% !important;
        left: 0px!important;
        padding: 10px !important;
        font-size: 13px!important;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
        font-size: 1rem !important;
    }
    .p-toast .p-toast-message .p-toast-icon-close {
        width: 1rem!important;
        height: 1rem !important;
    }
    .p-toast-icon-close-icon .pi{
        font-size: .7rem !important;
    }    
    .p-checkbox .p-checkbox-box{
        border: 1px solid #ced4da !important;
        width: 16px !important;
        height: 16px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon{
        font-size: 12px !important
    }
    
    .full-screen-popup .modal-dialog{
        padding: 0px 0px;
    }
    .p-radiobutton-label{
        font-size: 12px !important
    }
    .p-radiobutton .p-radiobutton-box{
        width: 16px !important;
        height: 16px !important;
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
        width: 8px !important;
        height: 8px !important;
    }
    .p-chip{
        font-size: 11px !important;
    }
    .p-chip .pi-chip-remove-icon {
        font-size: 9px !important;
        margin-left: 0.3rem !important;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 13px !important;
    }
    .paccordion-head-bg .status-section{     
        font-size: 13px !important;
    }
    .btn .ng-fa-icon{
        font-size: 12px
    }
    .modal-body .p-datatable .p-paginator{
        justify-content: center;
    }
    .p-datatable .p-datatable-thead > tr > th .p-input-icon-left input {
        padding-left: 1.5rem !important;
        width: 100%;
    }
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
        min-width: 1rem;
        height: 1rem; 
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 1.5rem;
        height: 1.5rem;
        font-size: 12px;
    }
    .p-paginator .p-dropdown{
        min-width: 1.5rem;
        height: 1.5rem;
        font-size: 12px;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
        font-size: 12px;
    }
    .p-paginator .pi {
        font-size: 10px;
    }
}

