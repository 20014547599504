:host ::ng-deep {
    .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }
    .p-datatable-tbody > tr > td {
        padding: 6px 7px;
        word-wrap: break-word;
        font-size: 15px;
    }
    .p-paginator {
        padding: 0 0 0 0;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 2rem;
        height: 2rem;
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0.6rem 0.45rem;
        font-size: 15px;
    }
    .p-datatable-header .p-inputtext, .p-treetable-header .p-inputtext{
        padding-left: 30px !important;
    }
}
::ng-deep .p-dropdown-label {
    display: flex !important;
    align-items: center;
}
::ng-deep .p-paginator .p-dropdown {
    height: 2rem;
}
::ng-deep .p-paginator .pi {
    font-size: 12px;
}
@media screen and (max-width: 40rem) {
    :host ::ng-deep {
        .p-datatable {
            &.p-datatable-responsive-demo {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }
                .p-datatable-tbody > tr > td {
                    text-align: left;
                    display: block;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0.4rem 7px 0.4rem 0px;
                    font-size: 13px;
                    .p-column-title {
                        width: 40%;
                        display: inline-block;
                        margin: -0.4em 1em -0.4em -0.4rem;
                        font-weight: 700;
                        word-wrap: anywhere;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 13px;
                        padding: 0px 0px 0px 0.4rem;
                    }

                    &:last-child {
                        border-bottom: 1px solid var(--surface-d);
                        padding-bottom: 10px;
                    }
                }
            }
        }
        .p-datatable .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td {
            padding: 0.4rem 0.45rem;
            font-size: 13px;
        }
        .p-paginator .p-paginator-first,
        .p-paginator .p-paginator-prev,
        .p-paginator .p-paginator-next,
        .p-paginator .p-paginator-last {
            min-width: 1rem;
            height: 1rem;
        }
        .p-paginator .p-paginator-pages .p-paginator-page {
            min-width: 1.5rem;
            height: 1.5rem;
            font-size: 12px;
        }
        .p-paginator .p-dropdown {
            min-width: 1.5rem;
            // height: 1.5rem;
            font-size: 12px;
        }
        .p-paginator .p-dropdown .p-dropdown-label {
            font-size: 12px;
        }
        .p-paginator .pi {
            font-size: 10px;
        }
    }
    .mb-tbl-top {
        justify-content: center;
    }
}
::ng-deep .pi-spinner:before {
    color: var(--theme-primary-color);
}
::ng-deep .p-component-overlay {
    background-color: rgb(232 245 233 / 43%) !important;
    transition-duration: 0.2s;
}

::ng-deep td > a,
::ng-deep .p-datatable-responsive-demo .p-datatable-tbody > tr > td > span a {
    color: var(--theme-primary-color) !important;
    text-decoration: none !important;
}
::ng-deep td {
    cursor: pointer !important;
}
::ng-deep th > input {
    width: calc(100% - 50px) !important;
}
